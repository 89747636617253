/*
* 업무구분:
* 화면 명:
* 화면 설명:
* 화면 접근권한:
*/
<template>
  <ur-page-container title="라이프맵핑 안내" :show-title="true" type="subpage" @on-header-left-click="fn_HistoryBack">
    <!-- 라이프맴핑 전체 S -->
    <ur-box-container direction="column" align-v="start" align-h="center" componentid=""  class="nw-qw-wrap nw-life-wrap">
      <!-- 라이프맴핑 안내 메인 S -->
      <ur-box-container align-h="center" class="nw-qw-main nw-life-main">
        <div class="nw-life-box">
          <span class="unse-stit"><b>건강자산</b></span>
          <strong class="unse-tit">라이프맵핑</strong>
          <span class="unse-txt">인생지도를 통해 고객님의 <br>건강자산에 대해 안내해 드리겠습니다</span>
          <div class="btn-area">
            <mo-button class="btn-start ty1" @click="fn_moveList">
              불러오기
              <i class="ic-more"/>
            </mo-button>   
            <mo-button class="btn-start" @click="fn_moveBseInfoInpt">
              시작하기
              <i class="ic-more"/>
            </mo-button>           
          </div>
        </div>
      </ur-box-container>
      <!-- 라이프맴핑 안내 메인 E -->
    </ur-box-container>
    <!-- 라이프맴핑 전체 E -->
  </ur-page-container>              
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT700M",
    screenId: "MSPCT700M",
    components: {
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      }
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created () {
      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    },
    beforeDestroy () {
      // backKey event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_moveBseInfoInpt () {
        this.$router.push( { name: 'MSPCT703M' } )
      },
      fn_moveList() {
        this.$router.push( { name: 'MSPCT701M' } )
      },
      fn_HistoryBack() {
        this.$router.push( { name: 'MSPBC002M' } )
      }
      
    }
   }
</script>
<style scoped>
.ur-box-container.nw-life-wrap {
  padding: 0;
}
</style>